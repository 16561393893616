
<template>
  <div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data () {
    return {
    }
  },
  methods: {
    social_login(provider,token) {
      this.$store.dispatch('auth/SocialLoginCallback',{provider, token}).then((response) => {
        if(response.data.access_token){
          this.$router.push({
            name: 'dashboard'
          })
        }else {
          this.$router.push({
            name: 'login'
          })
        }
      }).catch((error)=>{
        this.$router.push({
          name: 'login'
        })
      })
    }
  },
  beforeCreate(){
      this.$vs.loading()
  },
  created() {
    this.social_login(this.$route.params.provider,this.$route.query.code)
  }
}
</script>

<style lang="scss">
  #page-login {
    .social-login-buttons {
      .bg-facebook {
        background-color: #1551b1
      }

      .bg-twitter {
        background-color: #00aaff
      }

      .bg-google {
        background-color: #4285F4
      }

      .bg-github {
        background-color: #333
      }
    }
  }
</style>
